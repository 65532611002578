import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import Modal from "react-modal"
import { useMediaPredicate } from "react-media-hook"
import { useStaticQuery, graphql, withPrefix, navigate } from "gatsby"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import { Section, Container } from "../../../components/grid/grid"

import yellow1 from "../../../assets/img/balloons/balloon1_n.png"
import pink2 from "../../../assets/img/balloons/balloon2_n.png"
import yellow3 from "../../../assets/img/balloons/balloon3_n.png"
import pink4 from "../../../assets/img/balloons/balloon4_n.png"
import firstPop from "../../../assets/img/balloons/popBalloon1.png"
import secondPop from "../../../assets/img/balloons/popBalloon2.png"
import thirdPop from "../../../assets/img/balloons/popBalloon3.png"
import fourthPop from "../../../assets/img/balloons/popBalloon4.png"
import popupSatu from "../../../assets/img/balloons/1persen.png"
import popupSatuKomaLima from "../../../assets/img/balloons/1koma5persen.png"
import popupDua from "../../../assets/img/balloons/2persen.png"
import popupDuaKomaLima from "../../../assets/img/balloons/2koma5persen.png"
import bgBalloonDesktop from "../../../assets/img/balloons/newBg_ujpg.jpg"
import bgBalloonMobile from "../../../assets/img/balloons/newBg_375x812.png"
import cursor from "../../../assets/img/balloons/cursor.png"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const axios = require("axios")

const SUBMIT_POP = gql`
  mutation SubmitFormSurpriseBalloon(
    $fullName: String
    $phoneNumber: String
    $email: String
    $product: String
    $block: String
    $unit: String
    $salesName: String
    $officeName: String
    $discount: String
  ) {
    submit2SurpriseBalloonWfh2(
      input: {
        clientMutationId: ""
        fullName: $fullName
        phoneNumber: $phoneNumber
        email: $email
        product: $product
        block: $block
        unit: $unit
        salesName: $salesName
        officeName: $officeName
        discount: $discount
      }
    ) {
      status
      message
    }
  }
`

const SurpriseBalloonPopPage = (location) =>{
  const [submitPop, { loading: submitPopLoading }] = useMutation(SUBMIT_POP)
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const [successModal, setSuccessModal] = useState(false)
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }
  const handleSuccessModal = () => {
    setSuccessModal(false)
    window.location.href = "/extrasurprise"
  }

  useEffect(() => {
    let balloon1 = new Image()
    let balloon2 = new Image()
    let balloon3 = new Image()
    let balloon4 = new Image()

    let popBalloon1 = new Image()
    let popBalloon2 = new Image()
    let popBalloon3 = new Image()
    let popBalloon4 = new Image()

    balloon1.src = yellow1
    balloon2.src = pink2
    balloon3.src = yellow3
    balloon4.src = pink4

    popBalloon1.src = firstPop
    popBalloon2.src = secondPop
    popBalloon3.src = thirdPop
    popBalloon4.src = fourthPop

    let balloons = []
    var animProp = {animate: false}

    var canvasInit = function(lastTime, animProp){
      var ctx = document.getElementById('canvasBalloon').getContext('2d')
      var canvasWidth = ctx.canvas.width
      var canvasHeight = ctx.canvas.height
      var spawnRateOfDescent=1
      var lastSpawn = 1
      var mouseX = 0
      var mouseY = 0
      ctx.imageSmoothingQuality = "high"
      ctx.imageSmoothingEnabled = true
      ctx.mozImageSmoothingEnabled = true
      ctx.webkitImageSmoothingEnabled = true
      ctx.msImageSmoothingEnabled = true
      
      function sendSms(param) {
        const url = "https://sinarmasland.com/api/sms/pop"
        // const url = "https://newsml.coda-technology.com/api/sms/pop"
        var data = JSON.stringify({
          name: param.name,
          disc: param.disc,
          product: param.product,
          noBlock: param.noBlock,
          noUnit: param.noUnit,
          salesName: param.salesName,
          phoneNumber: param.phone,
        })
        const config = {
          method: "POST",
          url: url,
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          data: data,
        }

        axios(config)
        .then(function(response) {
          console.log(JSON.stringify(response.data))
        })
        .catch(function(error) {
          console.log(error)
        })
      }


      function Balloon(){
        this.render = function(balloon, x ,y,x1,y1){
          ctx.drawImage(balloon,x,y,x1,y1)
        }
      }
      
      function Pop(){
        this.render = function(img,x,y,x1,y1){
          ctx.drawImage(img,x,y,x1,y1)
          animProp.animate = false
        }
         
        this.clear = function(x,y){
          ctx.clearRect(x,y,x+10,y+10)
        } 
      }
      
      var balon = new Balloon()
      var POP = new Pop()
      //random ballon generation , adding to object
      
      function addBalloon(){
        var x1 = 0
        var y1 = canvasHeight
        var rand = Math.random()
        var nwd = new Date()
        var seconds = nwd.getSeconds()
        var randomType = ""
        var textBalloon = ""
        var arr = ['1%', '1.5%', '2%', '2.5%']

        if(seconds == 1 || seconds == 11 || seconds == 21 || seconds == 31 || seconds == 41 || seconds == 51){
          randomType="balloon1"
          arr.sort(()=> Math.random() - 0.5)
          textBalloon = arr[0]
          x1 = canvasWidth - 750
          lastSpawn = lastTime
          balloons.push({x:x1,y:y1,type:randomType, value:textBalloon}) 
        }else if(seconds == 2 || seconds == 12 || seconds == 22 || seconds == 32 || seconds == 42 || seconds == 52){
            randomType="balloon2"
            arr.sort(()=> Math.random() - 0.5)
            textBalloon = arr[1]
            x1 = canvasWidth - 550;
            lastSpawn = lastTime
            balloons.push({x:x1,y:y1,type:randomType, value:textBalloon}) 
        }else if(seconds == 3 || seconds == 13 || seconds == 23 || seconds == 33 || seconds == 43 || seconds == 53){
            randomType="balloon3"
            arr.sort(()=> Math.random() - 0.5)
            textBalloon = arr[2]
            x1 = canvasWidth - 350;
            lastSpawn = lastTime
            balloons.push({x:x1,y:y1,type:randomType, value:textBalloon}) 
        }else if(seconds == 4 || seconds == 14 || seconds == 24 || seconds == 34 || seconds == 44 || seconds == 54){
            randomType="balloon4"
            arr.sort(()=> Math.random() - 0.5)
            textBalloon = arr[3]
            x1 = canvasWidth - 150;
            lastSpawn = lastTime
            balloons.push({x:x1,y:y1,type:randomType, value:textBalloon}) 
        }
      }
      
      // animate function 
      var animate = function(){
        if (animProp.animate) {
          var time = Date.now()
          var nwd = new Date()
          var seconds = nwd.getSeconds()
          if(seconds > lastSpawn) {
            lastSpawn = seconds
            addBalloon()
          }else{
            lastSpawn = lastTime
          }

          var nwd3 = new Date()
          var seconds3 = nwd3.getSeconds()
          lastTime = seconds3
          requestAnimationFrame(function(){
            animate()
          })
            
          for (var i = 0; i < balloons.length; i++) { 
            var object = balloons[i]
            ctx.clearRect(object.x,object.y,120,379)
            if(resizeScreen){
              object.y -= (spawnRateOfDescent - 0.5)
            } else {
              object.y -= spawnRateOfDescent
            }
              
            if(resizeScreen){
              if(object.type === "balloon1"){
                balon.render(balloon1,object.x,object.y,90,40)
              }else if(object.type === "balloon2"){
                balon.render(balloon2,object.x,object.y,90,40)
              }else if(object.type === "balloon3"){
                balon.render(balloon3,object.x,object.y,90,40)
              }else if(object.type === "balloon4"){
                balon.render(balloon4,object.x,object.y,90,40)
              }
            }else{
              if(object.type === "balloon1"){
                balon.render(balloon1,object.x,object.y,80,220)
              }else if(object.type === "balloon2"){
                balon.render(balloon2,object.x,object.y,80,220)
              }else if(object.type === "balloon3"){
                balon.render(balloon3,object.x,object.y,80,220)
              }else if(object.type === "balloon4"){
                balon.render(balloon4,object.x,object.y,80,220)
              }
            }
            
            if(object.y <= 0){
              ctx.clearRect(object.x,object.y,800,800)
              balloons.splice(i,1)
            } 
          }
        }
      }
      
      if (animProp.animate) {
        animate()
      }
      
      /////////////////////////////////////////////////////
      /**Listeners**/
      //////////////////////////////////////////////////// 
      ctx.canvas.addEventListener('mousedown',function(event){
        //mouseX = event.offsetX - ctx.canvas.offsetLeft
        mouseX = event.offsetX
        mouseY = event.offsetY  - ctx.canvas.offsetTop
        console.log(event.offsetX);

        let poppedBalloon
        if(resizeScreen){
            if(mouseX >= 25 && mouseX <= 60) {
                poppedBalloon = "balloon1"
            } else if(mouseX >= 110 && mouseX <= 160) {
                poppedBalloon = "balloon2"
            } else if(mouseX >= 190 && mouseX <= 240) {
                poppedBalloon = "balloon3"
            } else if(mouseX >= 270 && mouseX <= 320) {
                poppedBalloon = "balloon4"
            }
        } else {
            if(mouseX >= 110 && mouseX <= 290) {
                poppedBalloon = "balloon1"
            } else if(mouseX >= 575 && mouseX <= 755) {
                poppedBalloon = "balloon2"
            } else if(mouseX >= 1035 && mouseX <= 1225) {
                poppedBalloon = "balloon3"
            } else if(mouseX >= 1510 && mouseX <= 1690) {
                poppedBalloon = "balloon4"
            }
        }
        
        for (var i = 0; i < balloons.length; i++){ 

          //if((mouseY < (balloons[i].y+315)) && (mouseX < (balloons[i].x))){
          if(poppedBalloon === balloons[i].type){
            ctx.clearRect(balloons[i].x,balloons[i].y,120,379)
            console.log('balon yang di pop: '+balloons[i].type.slice(0,-1))
            // var popBalloonSlice = balloons[i].type.slice(0,-1)
            var popBalloon = balloons[i].type
            var balloonValue = balloons[i].value
            if(resizeScreen){
              if(popBalloon == "balloon1"){
                POP.render(popBalloon1, balloons[i].x, balloons[i].y,90,30)
              }else if(popBalloon == "balloon2"){
                POP.render(popBalloon2, balloons[i].x, balloons[i].y,90,30)
              }else if(popBalloon == "balloon3"){
                POP.render(popBalloon3, balloons[i].x, balloons[i].y,90,30)
              }else{
                POP.render(popBalloon4, balloons[i].x, balloons[i].y,90,30)
              }
            }else{
              if(popBalloon == "balloon1"){
                POP.render(popBalloon1, balloons[i].x, balloons[i].y,120,120)
              }else if(popBalloon == "balloon2"){
                POP.render(popBalloon2, balloons[i].x, balloons[i].y,120,120)
              }else if(popBalloon == "balloon3"){
                POP.render(popBalloon3, balloons[i].x, balloons[i].y,120,120)
              }else{
                POP.render(popBalloon4, balloons[i].x, balloons[i].y,120,120)
              }
            }

            const name = sessionStorage.getItem("customerName")
            const email = sessionStorage.getItem("customerEmail")
            const phone = sessionStorage.getItem("customerPhone")
            const disc = balloonValue
            const product = sessionStorage.getItem("product")
            const noUnit = sessionStorage.getItem("noUnit")
            const noBlock = sessionStorage.getItem("noBlock")
            const salesName = sessionStorage.getItem("salesName")
            const officeName = sessionStorage.getItem("officeName")
            const formatNumber = phone.split("+").join("")

            submitPop({
              variables: {
                fullName: name,
                phoneNumber: formatNumber,
                email: email,
                product: product,
                block: noBlock,
                unit: noUnit,
                salesName: salesName,
                officeName: officeName,
                discount: disc,
              },
            })
            .then((res) => {
              console.log(res)
              sendSms({
                name,
                phone,
                disc,
                product,
                noUnit,
                noBlock,
                salesName,
              })
              sessionStorage.removeItem("customerName")
              sessionStorage.removeItem("customerEmail")
              sessionStorage.removeItem("customerPhone")
              sessionStorage.removeItem("product")
              sessionStorage.removeItem("noUnit")
              sessionStorage.removeItem("noBlock")
              sessionStorage.removeItem("salesName")
              sessionStorage.removeItem("officeName")
              sessionStorage.removeItem("isAgree")
            })
            .catch((e) => console.log(e))

            console.log('diskon yang diraih: '+balloons[i].value)
            balloons.splice(i,1)
            setSuccessModal(true)

            if(resizeScreen){
              if(balloonValue === "1%"){
                console.log('masukkesatu')
                document.querySelector("#modal-popup-balloon").style.background = `url('${popupSatu}') no-repeat`
                document.querySelector("#modal-popup-balloon").style.backgroundSize = `contain`
              }else if(balloonValue === "1.5%"){
                console.log('masukkesatulima')
                document.querySelector("#modal-popup-balloon").style.background = `url('${popupSatuKomaLima}') no-repeat`
                document.querySelector("#modal-popup-balloon").style.backgroundSize = `contain`
              }else if(balloonValue === "2%"){
                console.log('masukkedua')
                document.querySelector("#modal-popup-balloon").style.background = `url('${popupDua}') no-repeat`
                document.querySelector("#modal-popup-balloon").style.backgroundSize = `contain`
              }else if(balloonValue === "2.5%"){
                console.log('masukkedualima')
                document.querySelector("#modal-popup-balloon").style.background = `url(${popupDuaKomaLima}) no-repeat`
                document.querySelector("#modal-popup-balloon").style.backgroundSize = `contain`
              }
            }else{
              if(balloonValue === "1%"){
                console.log('masukkesatu')
                document.querySelector("#modal-popup-balloon").style.background = `url('${popupSatu}') no-repeat`
              }else if(balloonValue === "1.5%"){
                console.log('masukkesatulima')
                document.querySelector("#modal-popup-balloon").style.background = `url('${popupSatuKomaLima}') no-repeat`
              }else if(balloonValue === "2%"){
                console.log('masukkedua')
                document.querySelector("#modal-popup-balloon").style.background = `url('${popupDua}') no-repeat`
              }else if(balloonValue === "2.5%"){
                console.log('masukkedualima')
                document.querySelector("#modal-popup-balloon").style.background = `url(${popupDuaKomaLima}) no-repeat`
              }
            }
            break
          }
        }
      })
    }

    // starting balloon pop
    animProp.animate = true
    var date = new Date()
    var time = date.getTime()
    var second2 = date.getSeconds()
    canvasInit(second2, animProp)
  }, [])

  useEffect(() => {
    let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")

    if(resizeScreen){
      document.querySelector("#canvasBalloon").style.background = `url('${bgBalloonMobile}') no-repeat`
      document.querySelector("#canvasBalloon").style.cursor = `url('${cursor}'), auto`
      document.querySelector("#canvasBalloon").style.backgroundSize = `cover`
      document.getElementsByClassName("logo-secondary")[1].style.display = `none`
      document.getElementsByClassName("logo-main")[1].style.display = `block`
    }else{
      document.querySelector("#canvasBalloon").style.background = `url('${bgBalloonDesktop}') no-repeat`
      document.querySelector("#canvasBalloon").style.cursor = `url('${cursor}'), auto`
      document.querySelector("#canvasBalloon").style.backgroundSize = `cover`
      document.getElementsByClassName("logo-secondary")[0].style.display = `none`
      document.getElementsByClassName("logo-main")[0].style.display = `block`

      for (let i = 0; i < navbarDesktop.length; i++) {
        navbarDesktop[i].style.color = "#000"
      }
    }
  }, [])

  return(
    <Layout location={location} currentLocation={location.uri}>
      <SEO title="Extra Surprise Pop" description="" />
      <Helmet>
        <body className="bd-page" />
      </Helmet>
      <Section className="sectionSurprise m-0 p-0 text-center">
        <Container className="containerSurprise text-center pt-5 pb-5">
          <canvas id="canvasBalloon" width="800" height="300"></canvas>
        </Container>
      </Section>
      <Modal
        isOpen={successModal}
        style={customStyles}
        contentLabel="result modal"
        overlayClassName="modal-overlay-general"
      >
        <div className="modal-popup-balloon modal-body text-center" id="modal-popup-balloon">
          <div className="container py-5 main-btn-close">
            {/* <h2>Balloon Modal</h2> */}
            <p className="m-0">
              {/* Some text... */}
            </p>
            <button
              className="btn btn-primary mt-4 btn-close-balloon"
              onClick={handleSuccessModal}
            >
              &times;
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  )
}
export default SurpriseBalloonPopPage
